import { template as template_a0e445e9eb754621823cbc5b380d6125 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a0e445e9eb754621823cbc5b380d6125(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
