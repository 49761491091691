import { template as template_176ed85710974532ba405a6d53e796d8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_176ed85710974532ba405a6d53e796d8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
