import { template as template_8dd0a7e60791448cae5e6e03fcded78f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_8dd0a7e60791448cae5e6e03fcded78f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
