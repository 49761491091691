import { template as template_36c60c989ce34d919df8b32300cf69b3 } from "@ember/template-compiler";
import { gt } from "truth-helpers";
import icon from "discourse/helpers/d-icon";
import number from "discourse/helpers/number";
const OpLikesCell = template_36c60c989ce34d919df8b32300cf69b3(`
  <td class="num likes">
    {{#if (gt @topic.op_like_count 0)}}
      <a href={{@topic.summaryUrl}}>
        {{number @topic.op_like_count}}
        {{icon "heart"}}
      </a>
    {{/if}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
